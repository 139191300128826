import React from 'react';
import type { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';

import { resolvePage } from '@/utils/notion/resolvePage';
import { domain } from '@/utils/config/environment';
import { rootNotionPageId } from '@/utils/config/notion';

const NotionPage = dynamic(async () => (await import('@/components')).NotionPage);

export const getStaticProps: GetStaticProps = async () => {
	const pageId = rootNotionPageId || '';
	const page = await resolvePage(domain, pageId);

	return {
		props: page,
		revalidate: 10,
	};
};

const HomePage = ({ ...props }: typeof resolvePage) => <NotionPage {...props} />;

export default HomePage;
